import React, { useState } from 'react';
import getConfig from './config';
import logo from './logo.svg';
import styles from './App.module.scss';
const { REACT_ENV } = getConfig();

const App = () => {
  let [count, setCount] = useState(1);

  const handleClick = () => {
    setCount(count + 1);
  };

  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <img src={'/images/OTC_header_logo.png'} alt='otc-logo' />
        <h3>Current environment: {REACT_ENV}</h3>
        <img src={logo} className={styles.AppLogo} alt='react-logo' />
        <div>
          {count}
        </div>
        <div>
          <button onClick={handleClick}>CLICK HERE</button>
        </div>
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className={styles.AppLink}
          href='https://reactjs.org'
          target='_blank'
          rel='noopener noreferrer'
        >
        </a>
      </header>
    </div>
  );
};

export default App;
