const getEnv = () => {
  if (window.location.host.match(/localhost/)) {
    return 'local';
  } else if (window.location.host.match(/dev/)) {
    return 'dev';
  } else if (window.location.host.match(/test/)) {
    return 'test';
  } else if (window.location.host.match(/qa/)) {
    return 'qa';
  } else if (window.location.host.match(/stage/)) {
    return 'stage';
  }
};

const getConfig = () => {
  // Use env vars as default
  return {
    REACT_ENV: getEnv()
  };
};

export default getConfig;
